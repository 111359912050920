@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

body {
  background-image: url("./assets/img/bg-main.png");
  font-family: "Manrope", sans-serif;
} /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.Manrope {
  font-family: "Manrope", sans-serif;
}

.toggle-bg {
  /* background-color: #3958ff;
  border-color: #3958ff; */
  box-shadow: none !important;
}
.toggle-bg.bg-cyan-700 {
  background-color: #3958ff;
  border-color: #3958ff;
}
.toggle-bg::after {
  background-color: #3958ff;
  border-radius: 50%;
}
.toggle-bg.bg-cyan-700::after {
  background-color: #fff;
}
.radiomultiplier:checked + label {
  background-color: #3958ff;
  border-color: #5771ff;
}
.toggle-bg.dark\:bg-gray-700 {
  background-color: #fff !important;
}
.Toastify__toast {
  background-color: rgb(46 38 51 / 1) !important;
  border: 1px solid rgb(252 134 35 / 1) !important;
}
.Toastify__toast-body > div:last-child,
.Toastify__close-button > svg {
  color: #fff !important;
}
.Toastify__close-button {
  opacity: 1 !important;
}
.checkbox-type + label {
  cursor: pointer;
}
.checkbox-type:checked + label {
  background-color: #3958ff;
  border-color: #3958ff;
}
.checkbox-type:checked + label img {
  display: block;
}
.info-area:hover + span {
  opacity: 1;
}
.p-login + div > div {
  position: absolute;
}
.p-login + div {
  width: 100%;
  opacity: 0;
}
.p-login + div * {
  width: 100%;
}
.ring-area {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  background: transparent;

  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  font-family: sans-serif;
  font-size: 20px;
  color: rgb(57, 88, 255);
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px rgb(57, 88, 255);
}
.ring-area:before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 3px solid rgb(57, 88, 255);
  border-right: 3px solid rgb(57, 88, 255);
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}
.ring-area span {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: animate 2s linear infinite;
}
.ring-area span:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: rgb(57, 88, 255);
  top: -6px;
  right: -8px;
  box-shadow: 0 0 20px rgb(57, 88, 255);
}
.css-1919041 .base-Slider-thumb {
  border: 1px solid #fff;
  background-color: #3958ff !important;
}
.css-1919041 .base-Slider-rail {
  background-color: #262d61 !important;
  opacity: 1 !important;
}
.css-1919041 .base-Slider-track {
  background-color: #3958ff !important;
}
.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root,
.css-1omp2b7 {
  background-color: #171b35 !important;
  height: 32px !important;
  width: 32px !important;
  color: #cccccc !important;
  border: 1px solid #3b3d53 !important;
  font-size: 12px !important;
}
.css-1v2lvtn-MuiPaginationItem-root,
.css-16y9h98 {
  width: 32px !important;
  height: 32px !important;
  color: #cccccc !important;
  border-radius: 8px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #171b35 !important;
}
.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled,
.css-1omp2b7.Mui-disabled {
  opacity: 1 !important;
}
.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.css-1omp2b7.Mui-selected {
  background-color: #3958ff !important;
}
.Toastify {
  position: relative !important;
  z-index: 9999999 !important;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}
@media screen and (max-height: 851px) {
  .Deposit {
    height: unset;
    padding: 3rem 0rem;
    flex-direction: column;
  }

  .Deposit .skipbutton {
    position: static;
    margin-top: 2rem;
  }

  .rdrStaticRangeLabel {
    padding: 5px 10px !important;
  }
}

.customCalender {
  width: 100%;
  border-radius: 10px;
  overflow: "hidden";

  .rdrDateDisplayWrapper {
    color: #fff;
    background: transparent;
  }
  .rdrDateDisplayItem {
    background: #171b35;
    color: #fff !important;
    border-color: #171b35;
  }
  .rdrWeekDay {
    color: #fff !important;
  }
  .rdrNextPrevButton {
    background: #171b35;
    color: #fff;
  }
  .rdrPprevButton i {
    color: #fff;
    border-color: transparent rgb(255, 255, 255) transparent transparent;
  }
  .rdrNextButton i {
    color: #fff;
    border-color: transparent transparent transparent rgb(255, 255, 255);
  }
  .rdrMonth {
    width: 100%;
  }
  .rdrDateDisplayItem input {
    border-color: #fff;
    color: #fff !important;
  }
  .rdrMonthAndYearPickers select {
    color: #fff !important;
  }
  .rdrCalendarWrapper {
    background: #23284f;
    width: 100%;
    color: #fff;
    overflow: hidden;
    border-radius: 12px;
  }
  .rdrDayDisabled {
    background: #23284f;
    color: #fff;
  }
  .rdrDayNumber {
    color: #fff !important;
    span {
      color: #fff !important;
    }
  }

  .rdrDay {
    background: #23284f;
    color: #fff !important;
  }
  .rdrDefinedRangesWrapper {
    background: #23284f;
    width: 100%;
    border: none;
    color: #fff;
    overflow: hidden;
    border-radius: 12px;

    .rdrStaticRange {
      background: transparent;
      border-color: #171b35;
    }
    .rdrStaticRange:hover .rdrStaticRangeLabel {
      background: transparent;
    }
  }
}
@media (max-width: 639px) {
  .customCalender {
    .rdrDayNumber {
      color: #fff !important;
      top: none !important;
      bottom: 1px !important;
      
    }

    .rdrDay {
      height:2em !important;
      line-height: 2.000em !important;
    }
  }
}

.video-container {
  border-radius: 25px; 
  overflow: hidden;
}